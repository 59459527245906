import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-text-dialog',
    templateUrl: './text-dialog.component.html',
    styleUrls: ['./text-dialog.component.scss'],
})
export class TextDialogComponent {

    constructor(
        private dialogRef: MatDialogRef<TextDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            text: string;
            noClickClose: boolean;
            trueButtonText: string;
            falseButtonText: string;
        },
    ) {
        dialogRef.disableClose = !data.noClickClose;
    }


    closeDialogTrue() {
        this.dialogRef.close(true);
    }

    closeDialogFalse() {
        this.dialogRef.close(false);
    }
}
