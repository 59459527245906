import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';
import { MatLegacyCheckboxChange } from '@angular/material/legacy-checkbox';

export interface ICheckboxHeaderParams extends IHeaderParams {
    checked?: boolean;
    indeterminate?: boolean;
    onChecked?: (isChecked: boolean) => void;
    name?: string;
}

@Component({
    selector: 'app-ag-grid-checkbox-header',
    templateUrl: './ag-grid-checkbox-header.component.html',
    styleUrls: ['./ag-grid-checkbox-header.component.scss'],
})
export class AgGridCheckboxHeaderComponent implements IHeaderAngularComp {
    params: ICheckboxHeaderParams;

    checked: boolean;
    indeterminate: boolean;
    disabled = false;

    constructor() {
    }

    agInit(params: ICheckboxHeaderParams) {
        this.updateParams(params);
    }

    refresh(params: ICheckboxHeaderParams) {
        this.updateParams(params);
        return true;
    }

    change(event: MatLegacyCheckboxChange) {
        this.params.onChecked(event.checked);
    }

    private updateParams(params: ICheckboxHeaderParams) {
        this.params = params;
        this.checked = params.checked ? params.checked : false;
        this.indeterminate = params.indeterminate ? params.indeterminate : false;
    }
}
