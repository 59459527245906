import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA, MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { NotificationData } from '@models/notification';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: NotificationData,
                private snackBar: MatSnackBar) {
    }

    public dismiss(): void {
        this.snackBar.dismiss();
    }
}
