<app-dialog-container [loading]="false" (closeEvent)="closeDialog()" [title]="'Save Report'" data-cy="save-report-dialog-container">
    <div *ngIf="!allFiltersSelected">
        <p class="margin-bottom-1-em bold-font">Please make sure all required fields have been selected.</p>
    </div>
    <div *ngIf="allFiltersSelected">
        <p class="margin-bottom-1-em bold-font">Create New Saved Report</p>
        <div class="width-50-pct">
            <app-filter-input [property]="filters.newOrExistingFilter"></app-filter-input>
        </div>
        <app-filter-input [property]="filters.nameFilter" *ngIf="filters.newOrExistingFilter.Value.name === 'Create New'">
        </app-filter-input>
        <app-filter-input [property]="filters.selectExistingReportFilter" *ngIf="filters.newOrExistingFilter.Value.name === 'Update Existing'">

        </app-filter-input>

        <div>
            <app-filter-input
            [property]="filters.saveMetricSelectionsFilter"></app-filter-input>
        </div>
        <div>
            <app-filter-input
            [property]="filters.saveSegmentsSelectionsFilter"></app-filter-input>
        </div>
        <div class="flex-container">
            <app-filter-input [property]="filters.saveAsGlobalFilter"></app-filter-input>
        </div>
        <span *ngIf="filters.dateRangeOptionsFilter">Date Range Options</span>
        <app-filter-input *ngIf="filters.dateRangeOptionsFilter" class="width-50-pct" [property]="filters.dateRangeOptionsFilter" ></app-filter-input>

        <app-filter-input *ngIf="filters.dateRangeOptionsFilter" [hidden]="filters.dateRangeOptionsFilter.Value.name === 'Save Fixed Dates' || filters.dateRangeOptionsFilter.Value.name === 'Quick Date Options'"
                            [property]="filters.periodSelectFilter"></app-filter-input>
        <app-filter-input *ngIf="filters.dateRangeOptionsFilter" [hidden]="filters.dateRangeOptionsFilter.Value.name === 'Save Fixed Dates' || filters.dateRangeOptionsFilter.Value.name === 'Quick Date Options'"
                            [property]="filters.numberOfPeriodsFilter"></app-filter-input>

        <app-filter-input *ngIf="filters.dateRangeOptionsFilter" [hidden]="filters.dateRangeOptionsFilter.Value.name !== 'Quick Date Options'"
        [property]="filters.quickDateOptionsSelectFilter"></app-filter-input>

        <app-filter-input [property]="filters.addAsFavoriteFilter"></app-filter-input>

        <div class="level-right">
            <a class="button is-primary apply-button" (click)="close()" [attr.disabled]="!filters.nameFilter.isValid?true:null">Save</a>
        </div>
    </div>
</app-dialog-container>
