import { Component, OnInit } from '@angular/core';
import { PushNotificationService } from '@services/push-notification/push-notification.service';
import { AuthService } from '@services/auth/auth.service';
import { SwUpdate } from '@angular/service-worker';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CONFIRM_DIALOG, ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { interval } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    title = 'media-shrike-ui';
    constructor(
        private authSvc: AuthService,
        private pushNotificationService: PushNotificationService,
        private swUpdate: SwUpdate,
        private dialog: MatDialog,

    ) { }
    ngOnInit() {
        if(this.authSvc.getCustomerIds().customerId) {
            this.pushNotificationService.subscribeToNotification();
        }
        if (this.swUpdate.isEnabled) {
            // check for updates once/minute
            interval( 60 * 1000).subscribe(() => this.swUpdate.checkForUpdate());
            this.swUpdate.versionUpdates.subscribe(update => {
                if (update.type === 'VERSION_READY') {
                    const dialogRef = this.dialog.open(ConfirmDialogComponent,
                                                       { data: {
                                                           text: 'A new version of RateOptics is available. Would you like to start using it to see all the latest features?.',
                                                           autoConfirm: false,
                                                           noClickClose: true,
                                                           confirmText: 'Yes (This will cause the page to refresh and unsaved changes will be lost)',
                                                           denyText: 'No',
                                                           denyClose: true,
                                                       }});
                    dialogRef.afterClosed().subscribe(closeValue => {
                        if (closeValue === CONFIRM_DIALOG){
                            this.swUpdate.activateUpdate().then(_ => {
                                document.location.reload();
                            });
                        }

                    });
                }
            });
        }

    }
}
