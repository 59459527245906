import { Component, Input, OnChanges } from '@angular/core';
import {
    AutocompleteFilter,
} from '@models/filter-types';
import { LookupItem, StringLookupItem } from '@models/lookup';
import { getDisplayText, segmentTrack } from '@shared/helpers/functions/helpers';
import { Router } from '@angular/router';

@Component({
    selector: 'app-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent implements OnChanges{
    @Input() property: AutocompleteFilter<StringLookupItem | LookupItem>;
        @Input() overrideDisabled: boolean;

        disabled = false;

        constructor(private router: Router) {
        }

        ngOnChanges() {
            this.disabled = this.property?.isReadonly || this.overrideDisabled;
        }

        displayFn(option): string{
            return option && option.name ? option.name: '';
        }

        openedChange() {
            this.property.isOpened = true;
        }


        closedChange() {
            this.property.isOpened = false;
            // This forces a cascade update
            segmentTrack(
                'User Selected From Dropdown',
                {
                    dropdown: this.property.Key,
                    selection: getDisplayText(this.property.Value),
                    screen: this.router.url,
                },
            );
        }
}
