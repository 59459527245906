import { Injectable, NgZone } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { NotificationComponent } from '@shared/components/global/notification/notification.component';
import * as moment from 'moment';
import { dateFormatter } from '@shared/helpers/functions/helpers';

const DEFAULT_DURATION = 20000;
export type NotificationType = 'info' | 'error' | 'warning' | 'success';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {

    constructor(private snackBar: MatSnackBar, private zone: NgZone) {
    }

    public getAdminSaveMessage(setting: string) {
        const nextMonday = dateFormatter(moment().startOf('isoWeek').add(1, 'week').toDate(), 'fullDate');
        return `${setting} saved successfully. Changes will reflect after next rate refresh (${nextMonday}).`;
    }

    public openSnackBar(
        message: string,
        notificationType: NotificationType = 'info',
        duration: number = DEFAULT_DURATION,
        horizontalPosition: MatSnackBarHorizontalPosition = 'center',
        verticalPosition: MatSnackBarVerticalPosition = 'top',
    ): void {
        let icon: string;

        switch (notificationType) {
        case ('error'):
            icon = 'fas fa-exclamation-circle fa-fw ';
            break;
        case ('warning'):
            icon = 'fas fa-exclamation-triangle fa-fw ';
            break;
        case ('info'):
            icon = 'fas fa-info-circle fa-fw ';
            break;
        case ('success'):
            icon = 'fas fa-check-circle fa-fw ';
            horizontalPosition = 'right';
        }

        this.zone.run(() => {
            this.snackBar.openFromComponent(NotificationComponent, {
                duration,
                horizontalPosition,
                verticalPosition,
                panelClass: [notificationType],
                data: { message, type: notificationType, icon },
            });
        });
    }
}
